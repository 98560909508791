import React from 'react'
import PropTypes from 'prop-types'
import { prop } from '@soltalabs/ramda-extra'
import { useFormikContext } from 'formik'
import { useRadioGroup } from '@react-aria/radio'
import { useRadioGroupState } from '@react-stately/radio'
import { styled, s } from '@vega/styled'

const { number, string } = PropTypes

const Container = styled.div(
  s('flex justify-between text-sm text-grey-600'),
  prop('containerStyle')
)

const OuterTextContainer = styled.div(s('flex w-1/5 min-h-6 flex items-center'))

const InnerTextContainer = styled.div(s('flex w-full'))

const Text = styled.p({
  lineHeight: 1.5,
})

const OptionsContainer = styled.div(
  s('flex w-2/5 items-center justify-evenly'),
  prop('optionsContainerStyle'),
  ({ readOnly }) => readOnly && { pointerEvents: 'none' }
)

const RadioContext = React.createContext()

export const useRadioContext = () => React.useContext(RadioContext)

const LoanFlexibilityPreferenceField = ({
  containerStyle,
  optionsContainerStyle,
  readOnly,
  ...props
}) => {
  const { children, name } = props

  const { setFieldValue } = useFormikContext()
  const onChange = (value) => setFieldValue(name, value)

  const state = useRadioGroupState({ ...props, onChange })
  const { radioGroupProps } = useRadioGroup(props, state)

  return (
    <RadioContext.Provider value={state}>
      <Container containerStyle={containerStyle} {...radioGroupProps}>
        <OuterTextContainer style={s('text-left')}>
          <InnerTextContainer>
            <Text>
              Certainty of repayments and protection against interest rate rises
            </Text>
          </InnerTextContainer>
        </OuterTextContainer>
        <OptionsContainer
          optionsContainerStyle={optionsContainerStyle}
          readOnly={readOnly}
        >
          {children}
        </OptionsContainer>
        <OuterTextContainer style={s('text-right')}>
          <InnerTextContainer style={s('ml-a')}>
            <Text>Loan flexibility and the ability to make additional repayments</Text>
          </InnerTextContainer>
        </OuterTextContainer>
      </Container>
    </RadioContext.Provider>
  )
}

LoanFlexibilityPreferenceField.propTypes = {
  name: string.isRequired,
  defaultValue: number,
}

export default LoanFlexibilityPreferenceField
