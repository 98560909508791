import { isNilOrEmpty } from '@soltalabs/ramda-extra'

// Rounding value used with Math.round() to round number
// roundDigits = 2 -> return 100, so Math.round(num * 100)) / 100 will return a rounded number
const getRoundingValue = (roundDigits) => parseInt('1'.padEnd(roundDigits + 1, '0'), 10)

// Math.round() doesn't natively accept roundDigit so we recreate it with * and /
const roundNumber = (num, roundDigits) =>
  Math.round(num * getRoundingValue(roundDigits)) / getRoundingValue(roundDigits)

const thousand = 1000
const million = 1000000
const billion = 1000000000
const hundredBillion = 100000000000

/* eslint-disable complexity */
export const shortenLargeNumbers = (number, roundDigits = 2, placeholder = '-') => {
  if (isNilOrEmpty(number)) return placeholder

  let numberText

  switch (true) {
    case number >= thousand && number < million:
      numberText = `${roundNumber(number / thousand, roundDigits)}k`
      break

    case number >= million && number < billion:
      numberText = `${roundNumber(number / million, roundDigits)}M`
      break

    case number >= billion && number < hundredBillion:
      numberText = `${roundNumber(number / billion, roundDigits)}B`
      break

    default:
      return number
  }

  return numberText
}
