import React from 'react'
import { prop } from '@soltalabs/ramda-extra'
import { styled, s } from '@vega/styled'
import { TableHeader } from './TableHeader'
import { TableBody } from './TableBody'
import DefaultTableEmptyPlaceholder from './TableEmptyPlaceholder'

const Table = styled.table(
  s('flex flex-column h-full'),
  {
    overflow: 'auto',
    borderSpacing: 0,
    minWidth: '100%',
    border: '1px solid #F1F5F9',
  },
  prop('containerStyle')
)

function TableBase({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  prepareRow,
  page,
  onClick,
  tBodyStyle,
  testId,
  containerStyle,
  tableHeaderRowStyles,
  TableEmptyPlaceholder = DefaultTableEmptyPlaceholder,
  placeholderText,
  hasNoData,
}) {
  return (
    <Table {...getTableProps()} containerStyle={containerStyle} data-test-id={testId}>
      <TableHeader
        headerGroups={headerGroups}
        tableHeaderRowStyles={tableHeaderRowStyles}
      />
      {hasNoData ? (
        <TableEmptyPlaceholder placeholderText={placeholderText} />
      ) : (
        <TableBody
          tBodyStyle={tBodyStyle}
          getTableBodyProps={getTableBodyProps}
          prepareRow={prepareRow}
          page={page}
          onClick={onClick}
        />
      )}
    </Table>
  )
}

export { TableBase }
