import React from 'react'
import PropTypes from 'prop-types'
import { isNilOrEmpty } from '@soltalabs/ramda-extra'

import { ReactComponent as QuestionMarkIcon } from '../assets/images/question-mark-circle.svg'

import { styled, s } from '@vega/styled'

const Container = styled.div(
  s('flex flex-row items-center justify-center bg-transparent', {
    borderRadius: '6px 0 6px 6px',
    overflow: 'hidden',
  }),
  ({ size, showPlaceholder, src }) => ({
    width: size,
    height: size,
    backgroundColor: showPlaceholder ? '#58C6BD' : '#CCC',
    backgroundImage: `url(${src})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    '& > svg': {
      display: showPlaceholder ? 'block' : 'none',
    },
  })
)

function Avatar({ size = 32, placeholderIconSize = 18, src, ...props }) {
  return (
    <Container showPlaceholder={isNilOrEmpty(src)} src={src} size={size} {...props}>
      <QuestionMarkIcon width={placeholderIconSize} height={placeholderIconSize} />
    </Container>
  )
}

Avatar.propTypes = {
  src: PropTypes.string,
  size: PropTypes.number,
  questionMarkSize: PropTypes.number,
}

export default Avatar
