/* eslint-disable camelcase */
import React, { useLayoutEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { head } from '@soltalabs/ramda-extra'
import { selectProfileRoles } from '@vega/redux.profile'
import { DashboardRouter } from 'navigation/DashboardRouter'
import { USER_ROLES } from '@vega/constants'
import { UnsupportedScreenPage } from '@vega/components'
import { useLargeMediaQuery } from '@vega/styled'
import { AppSwitcher } from 'features/common'
import { routes } from './routes'
import { NavigationDrawer } from 'features/common/NavigationDrawer'
import { AccountSettings } from 'features/accountSettings'
import { WarehousesConfigRouter } from 'navigation/WarehousesConfigRouter'
import { fetchWarehouses } from 'modules/warehouses'

const { SUPER_ADMIN, BANKING_PORTFOLIO_ADMIN } = USER_ROLES
const { dashboard, warehousesConfigRouter, accountSettings } = routes

const rootUrlByRole = {
  [SUPER_ADMIN]: dashboard.root,
  [BANKING_PORTFOLIO_ADMIN]: dashboard.root,
}

export const ProtectedRouter = () => {
  const roles = useSelector(selectProfileRoles) ?? []
  const userCurrentRole = head(roles)
  const rootUrl = rootUrlByRole[userCurrentRole]

  const dispatch = useDispatch()

  const isLargeScreen = useLargeMediaQuery('larger')

  useLayoutEffect(() => {
    dispatch(fetchWarehouses())
  }, [dispatch])

  if (!isLargeScreen)
    return (
      <Switch>
        <UnsupportedScreenPage app="Neo Banking Portfolio" />
      </Switch>
    )

  return (
    <>
      <NavigationDrawer homeRoute="/" />
      <AppSwitcher />

      <Switch>
        <Route path={dashboard.root} component={DashboardRouter} />
        <Route path={warehousesConfigRouter.root} component={WarehousesConfigRouter} />
        <Route exact path={accountSettings.root} component={AccountSettings} />

        <Redirect to={rootUrl} />
      </Switch>
    </>
  )
}
