import React, { useCallback } from 'react'
import { isNotNilOrEmpty } from '@soltalabs/ramda-extra'
import { PropTypes } from 'prop-types'

import { useField } from 'formik'
import { MultiSelectInput } from './MultiSelectInput'

function MultiSelectField({
  name,
  label,
  placeholder,
  items,
  variant,
  onChange,
  errorMsgSelector = (error) => error,
  // Select value (key) for the underneath multi select input, NOT the formik value
  valueSelector = (values) => values,
  displayTextSelector = (values) => values,
  ...props
}) {
  const [field, meta, helpers] = useField({ name, multiple: true, ...props })

  const { value, onBlur } = field

  const { error, touched } = meta
  const { setValue } = helpers

  const defaultOnChange = useCallback(
    (selectedItems) => {
      setValue(selectedItems)
    },
    [setValue]
  )

  const hasError =
    touched &&
    isNotNilOrEmpty(errorMsgSelector(error)) &&
    // If error type is object, it means the field is an object field and the error comes from the nested field in side of the object.
    // We either want to use errorMsgSelector to select the error from nested field for the parent field or suppress it as passing object to
    // form message will cause runtime error
    typeof errorMsgSelector(error) !== 'object'

  return (
    <MultiSelectInput
      value={valueSelector(value)}
      displayTextSelector={displayTextSelector}
      error={error}
      hasError={hasError}
      variant={variant}
      errorMsgSelector={errorMsgSelector}
      onChange={onChange || defaultOnChange}
      onBlur={onBlur}
      label={label}
      placeholder={placeholder}
      items={items}
    />
  )
}

const { arrayOf, shape, string } = PropTypes

MultiSelectField.propTypes = {
  items: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
}

export { MultiSelectField }
