import React from 'react'
import { isNilOrEmpty } from '@soltalabs/ramda-extra'
import { useSelector } from 'react-redux'
import { styled, s } from '@vega/styled'
import { selectInspectedWarehouseId } from 'modules/warehouses'
import { DefaultWarehouse } from './defaultWarehouse'
import { FundingWarehouse } from './fundingWarehouse'

const Container = styled.div(
  s('h-full', {
    msOverflowStyle: 'none',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  })
)

export const WarehouseConfiguration = () => {
  const inspectedWarehouseId = useSelector(selectInspectedWarehouseId)

  return (
    <Container>
      {isNilOrEmpty(inspectedWarehouseId) ? <DefaultWarehouse /> : <FundingWarehouse />}
    </Container>
  )
}
