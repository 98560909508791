import React from 'react'
import { prop } from '@soltalabs/ramda-extra'
import { ReactComponent as DropdownArrowBase } from '@vega/components/src/assets/images/dropdownArrow.svg'
import { monthMap } from './monthMap'
import { styled, s } from '@vega/styled'

const Container = styled.div(
  s(
    'flex justify-between items-center text-grey-600 font-semibold px-4 py-4 bg-white rounded-lg border-1 border-solid border-grey-300',
    { userSelect: 'none', cursor: 'pointer', width: 117, height: 48 }
  ),
  prop('togglerStyle')
)

const DropdownArrow = styled(DropdownArrowBase)(
  s('text-grey-500', { width: 8, height: 4 })
)

function DefaultToggler({
  onTogglerClicked,
  togglePicker,
  selectedYear,
  selectedMonth,
  togglerStyle,
}) {
  const onClick = () => {
    onTogglerClicked()
    togglePicker()
  }

  return (
    <Container onClick={onClick} togglerStyle={togglerStyle}>
      <span>{monthMap[selectedMonth]}</span>{' '}
      <span style={s('mr-1')}>{selectedYear}</span>
      {<DropdownArrow />}
    </Container>
  )
}

export { DefaultToggler }
