import React from 'react'
import { isNilOrEmpty, prop, pluck, all } from '@soltalabs/ramda-extra'
import ParentSize from '@visx/responsive/lib/components/ParentSize'
import { EmptyLineChart } from './EmptyLineChart'
import { LineChartBase } from './LineChartBase'
import { DottedCircleLoader } from '@vega/components'
import { styled, s } from '@vega/styled'

const Container = styled.div(
  s(''),
  ({ width, height }) => ({ width, height }),
  prop('containerStyle')
)

function LineChart({
  data,
  height,
  approxHighestValueForEmptyChart,
  chartPadding,
  containerStyle,
  isLoading,
  ...otherProps
}) {
  const allData = pluck('data', data)

  if (isLoading)
    return (
      <Container
        containerStyle={containerStyle}
        style={s('flex justify-center items-center')}
      >
        <DottedCircleLoader size="lg" />
      </Container>
    )

  if (isNilOrEmpty(data) || all(isNilOrEmpty, allData))
    return (
      <Container containerStyle={containerStyle}>
        <ParentSize>
          {({ width: parentWidth, height: parentHeight }) => (
            <EmptyLineChart
              approxHighestValueForEmptyChart={approxHighestValueForEmptyChart}
              height={parentHeight}
              width={parentWidth}
              chartPadding={chartPadding}
            />
          )}
        </ParentSize>
      </Container>
    )

  return (
    <Container containerStyle={containerStyle}>
      <ParentSize>
        {({ width: parentWidth, height: parentHeight }) => (
          <LineChartBase
            lineChartData={data}
            height={parentHeight}
            width={parentWidth}
            chartPadding={chartPadding}
            {...otherProps}
          />
        )}
      </ParentSize>
    </Container>
  )
}

export { LineChart }
