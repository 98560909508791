import { prop } from '@soltalabs/ramda-extra'
import { createSelector } from '@reduxjs/toolkit'

import { createAsyncThunkSelectors } from 'utils/createAsyncThunkSelectors'

const selectAnalytics = prop('analytics')

export const {
  selectIsPending: selectIsFetchingDashboardAnalytics,
} = createAsyncThunkSelectors('analytics/fetchDashboardAnalytics')

export const selectDashboardAnalytics = createSelector(
  selectAnalytics,
  prop('dashboardAnalytics')
)
