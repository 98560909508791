import React from 'react'
import { s, styled } from '@vega/styled'
import { format } from 'date-fns'
import PropTypes from 'prop-types'

const Container = styled.div(s('flex justify-between items-center bg-white'), {
  padding: '24px 28px',
})
const Greeting = styled.div(s('flex flex-column text-grey-500'))
const WelcomeText = styled.span(s('text-xl text-black mb-2'))
const DateText = styled.span(s('text-xs'))

function GreetingTopBar({ userName, RightSideComponent, ...otherProps }) {
  return (
    <Container {...otherProps}>
      <Greeting>
        <WelcomeText>👋 {userName},</WelcomeText>
        <DateText>{format(new Date(), 'EEEE, d MMM yyyy')}</DateText>
      </Greeting>

      {RightSideComponent}
    </Container>
  )
}

GreetingTopBar.propTypes = {
  userName: PropTypes.string.isRequired,
  RightSideComponent: PropTypes.object,
}

export default GreetingTopBar
