import React from 'react'
import {
  AnimatedAxis,
  AnimatedGrid,
  AnimatedLineSeries,
  XYChart,
  Tooltip,
} from '@visx/xychart'
import { find, propEq, identity } from '@soltalabs/ramda-extra'
import PropTypes, { arrayOf } from 'prop-types'
import { curveCardinal } from '@visx/curve'
import { format } from 'date-fns'
import { styled, s } from '@vega/styled'
import { defaultStyles } from '@visx/tooltip'
import { shortenLargeNumbers } from 'features/dashboard/utils/shortenLargeNumbers'

const ChartContainer = styled.div(
  s('w-full h-full', {
    '.visx-axis-tick': {
      text: s('font-semibold text-sm', {
        fill: s('text-grey-600').color,
      }),
    },
  })
)
const TooltipValueContainer = styled.div(s('flex items-center'))
const ColoredSquare = styled.div(
  s('', { borderRadius: 6, width: 8, height: 8 }),
  ({ color }) => ({
    backgroundColor: color,
  })
)

const TooltipInnerContainer = styled.div(s('py-3 pl-1 pr-3'))
const TooltipDate = styled.span(s('text-grey-600 font-normal text-xs'))
const TooltipValue = styled.span(s('font-semibold text-grey-800'))

const defaultChartPadding = { left: 60, top: 35, bottom: 38, right: 27 }

const accessors = {
  xAccessor: (d) => new Date(d?.x),
  yAccessor: (d) => d?.y,
}

export const LineChartBase = ({
  height = 300,
  width,
  lineChartData,
  chartPadding = defaultChartPadding,
  numOfTickOnYAxis = 7,
  numOfTickOnXAxis = 5,
  tickLabelOffsetXOfAxisY = -20,
  tickLabelOffsetYOfAxisY = 0,
  tickLabelOffsetXOfAxisX = 0,
  tickLabelOffsetYOfAxisX = 20,
  yAxisProps,
  xAxisProps,
  showVerticalCrosshair = true,
  showTooltip = true,
  showYAxis = true,
  showXAxis = true,
  tooltipValueFormatter = identity,
}) => {
  const [keyOfHoveredLineSeries, setKeyOfHoveredLineSeries] = React.useState()
  const hoveredLineSeries = find(propEq('key', keyOfHoveredLineSeries), lineChartData)
  const hoveredLineSeriesColor = hoveredLineSeries?.lineStyle?.color

  return (
    <ChartContainer>
      <XYChart
        height={height}
        width={width}
        captureEvents
        onPointerMove={({ key }) => {
          if (key !== keyOfHoveredLineSeries) setKeyOfHoveredLineSeries(key)
        }}
        pointerEventsDataKey="nearest"
        xScale={{ type: 'utc', nice: 'day' }}
        yScale={{ type: 'linear' }}
        margin={chartPadding}
      >
        {showYAxis && (
          <AnimatedAxis
            hideAxisLine
            hideTicks
            orientation="left"
            tickLabelProps={() => ({
              dx: tickLabelOffsetXOfAxisY,
              dy: tickLabelOffsetYOfAxisY,
            })}
            tickFormat={(d) => shortenLargeNumbers(d)}
            numTicks={numOfTickOnYAxis}
            {...xAxisProps}
          />
        )}
        {showXAxis && (
          <AnimatedAxis
            hideAxisLine
            hideTicks
            orientation="bottom"
            tickLabelProps={() => ({
              dx: tickLabelOffsetXOfAxisX,
              dy: tickLabelOffsetYOfAxisX,
            })}
            tickFormat={(d) => format(d, 'dd MMM')}
            numTicks={numOfTickOnXAxis}
            {...yAxisProps}
          />
        )}

        <AnimatedGrid
          columns={false}
          numTicks={4}
          lineStyle={{
            stroke: s('text-grey-100').color,
            strokeLinecap: 'square',
            strokeDasharray: 6,
            strokeWidth: 1,
          }}
        />

        {lineChartData.map(({ data, lineStyle, key, ...otherProps }) => (
          <AnimatedLineSeries
            key={key}
            enableEvents={true}
            dataKey={key}
            data={data}
            curve={curveCardinal}
            style={{ stroke: lineStyle.color, strokeWidth: 3 }}
            {...accessors}
            {...otherProps}
          />
        ))}

        {showTooltip && (
          <Tooltip
            snapTooltipToDatumX
            snapTooltipToDatumY
            showDatumGlyph
            showVerticalCrosshair={showVerticalCrosshair}
            verticalCrosshairStyle={{
              stroke: s('text-grey-200').color,
              strokeLinecap: 'square',
              strokeDasharray: 6,
              strokeWidth: 1,
            }}
            glyphStyle={{
              fill: hoveredLineSeriesColor,
              strokeWidth: 2,
            }}
            offsetLeft={-80}
            offsetTop={-110}
            style={{ ...defaultStyles, ...s('rounded-lg') }}
            renderTooltip={({ tooltipData }) => {
              const nearestDatum = tooltipData.nearestDatum.datum

              return (
                <TooltipInnerContainer>
                  <div>
                    <div style={s('mb-3')}>
                      <TooltipDate>
                        {format(accessors.xAccessor(nearestDatum), 'dd MMM yyyy')}
                      </TooltipDate>
                    </div>
                    <TooltipValueContainer>
                      <ColoredSquare style={s('mr-1')} color={hoveredLineSeriesColor} />
                      <span style={s('text-grey-500 font-normal mr-1')}>
                        Loan granted
                      </span>
                      <TooltipValue>
                        {tooltipValueFormatter(accessors.yAccessor(nearestDatum))}
                      </TooltipValue>
                    </TooltipValueContainer>
                  </div>
                </TooltipInnerContainer>
              )
            }}
          />
        )}
      </XYChart>
    </ChartContainer>
  )
}

const { object, number, string, bool, oneOfType, func } = PropTypes

LineChartBase.propTypes = {
  lineChartData: arrayOf(
    PropTypes.shape({
      data: arrayOf(
        PropTypes.shape({
          x: oneOfType([string, object]),
          y: number,
        })
      ),
      lineStyle: PropTypes.shape({
        color: string,
      }),
      key: string,
    })
  ),
  height: number.isRequired,
  width: number.isRequired,
  chartPadding: PropTypes.shape({
    top: number,
    right: number,
    bottom: number,
    left: number,
  }),
  yAxisProps: object,
  xAxisProps: object,
  numOfTickOnYAxis: number,
  numOfTickOnXAxis: number,
  tickLabelOffsetYOfAxisX: number,
  tickLabelOffsetYOfAxisY: number,
  tickLabelOffsetXOfAxisX: number,
  tickLabelOffsetXOfAxisY: number,
  showVerticalCrosshair: bool,
  showTooltip: bool,
  showYAxis: bool,
  showAxisX: bool,
  tooltipValueFormatter: func,
}
