import React from 'react'
import PropTypes from 'prop-types'
import { styled, s } from '@vega/styled/v2'
import { DottedCircleLoader } from '@vega/components'
import { prop } from '@soltalabs/ramda-extra'

const { object, oneOf, bool } = PropTypes

const getStyleByVariant = {
  contained: s('bg-secondary', {
    '&:hover:not([disabled])': s('bg-secondary', {
      boxShadow:
        '0px 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3);',
    }),
    '&:active:not([disabled])': {
      boxShadow: 'none',
    },
  }),
  outlined: s('border-1 border-primary text-primary bg-white', {
    '&:hover:not([disabled])': s('bg-white', {
      boxShadow:
        '0px 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3);',
    }),
    '&:active:not([disabled])': {
      boxShadow: 'none',
    },
  }),
  text: s('bg-transparent text-primary px-4 py-2', {
    '&:hover:not([disabled])': s('bg-grey-100'),
    '&:active:not([disabled])': s('bg-grey-300'),
  }),
}

const styleBySize = {
  sm: s('px-3 py-3'),
  md: s('px-4 py-3'),
  lg: s('px-8 py-3'),
}

const getStylesBasedOnProps = (variant, size) => {
  return { ...getStyleByVariant[variant], ...styleBySize[size] }
}

const Container = styled('button', {
  shouldForwardProp: (prop) =>
    !['loading', 'containerStyle', 'variant', 'size'].includes(prop),
})(
  s('flex items-center justify-center border-0 font-semibold'),
  ({ variant, size }) => getStylesBasedOnProps(variant, size),
  ({ loading }) => loading && { opacity: 0.5, pointerEvents: 'none' },
  {
    cursor: 'pointer',
    outline: 0,
    borderRadius: 6,
    ':disabled': s('bg-grey-200 text-grey-600', { pointerEvents: 'none' }),
  },
  prop('containerStyle')
)

function Button({
  variant = 'contained',
  size = 'md',
  loading = false,
  StartIcon,
  EndIcon,
  children,
  containerStyle,
  loaderProps = {},
  type = 'button',
  testId,
  ...otherProps
}) {
  return (
    <Container
      variant={variant}
      size={size}
      loading={loading}
      containerStyle={containerStyle}
      type={type}
      data-test-id={testId}
      {...otherProps}
    >
      {StartIcon}

      {loading ? <DottedCircleLoader size={size} {...loaderProps} /> : children}

      {EndIcon}
    </Container>
  )
}

Button.propTypes = {
  variant: oneOf(['contained', 'outlined', 'text']),
  size: oneOf(['sm', 'md', 'lg']),
  StartIcon: object,
  EndIcon: object,
  loading: bool,
}

export default Button
