/* eslint-disable react/jsx-key */
import React from 'react'
import { styled, s } from '@vega/styled'
import { noop, path, prop } from '@soltalabs/ramda-extra'

const Tr = styled.tr(
  s('flex text-black no-decoration text-base'),
  {
    height: '64px',
    '&:hover': { backgroundColor: '#F7FCFC' },
  },
  prop('customRowStyle')
)

const Td = styled.td(
  s('flex items-center px-3 text-left'),
  {
    margin: '0',
    color: '#18181b',
    borderBottom: '1px solid #F1F5F9',
  },
  prop('tableCellStyles')
)

const Tbody = styled.tbody(
  s('text-center'),
  {
    maxHeight: '95%',
  },
  prop('tBodyStyle')
)

function TableBody({
  tBodyStyle,
  getTableBodyProps,
  prepareRow,
  page,
  onClick = noop,
}) {
  return (
    <Tbody tBodyStyle={tBodyStyle} {...getTableBodyProps()}>
      {page.map((row) => {
        prepareRow(row)

        return (
          <Tr
            key={row.id}
            data-test-id={row.original.id}
            {...row.getRowProps({
              onClick: () => onClick(row.original.id),
            })}
            customRowStyle={path(['original', 'customRowStyle'], row)}
          >
            {row.cells.map((cell, index) => {
              return (
                <Td
                  key={index}
                  tableCellStyles={path(['column', 'tableCellStyles'], cell)}
                  {...cell.getCellProps()}
                >
                  {cell.render('Cell')}
                </Td>
              )
            })}
          </Tr>
        )
      })}
    </Tbody>
  )
}

export { TableBody }
