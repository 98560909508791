import React from 'react'
import { useSelector } from 'react-redux'
import {
  propEq,
  isNilOrEmpty,
  isNotNilOrEmpty,
  mapObjIndexed,
  map,
  pluck,
} from '@soltalabs/ramda-extra'
import { LineChart } from './LineChart'
import { styled, s } from '@vega/styled'
import { ColorLegend } from './ColorLegend'
import { useWarehouseDashboard } from 'features/dashboard/warehouseDashboardContext'
import { selectWarehouses } from 'modules/warehouses'
import { shortenLargeNumbers } from 'features/dashboard/utils/shortenLargeNumbers'

const chartHeight = 517

const TABS = {
  LOAN_GRANTED: 'loanGranted',
  WALVR: 'WALVR',
  AVERAGE_LOAN_SIZE: 'averageLoanSize',
}

const Container = styled.div(
  s(
    'flex flex-column justify-between items-center bg-white rounded-lg border-1 border-solid border-grey-200 w-full py-4 px-6',
    {
      width: 1300,
      height: chartHeight,
      boxShadow:
        '0px 4px 8px rgba(34, 99, 94, 0.08), 1px 4px 24px rgba(34, 99, 94, 0.12)',
    }
  )
)

const Header = styled.div(s('flex w-full items-center justify-between'))

const Title = styled.h2(s('text-grey-800 text-xl font-semibold'))
const TabsContainer = styled.div(s('relative flex items-center justify-center'))
const Tab = styled.div(
  s('text-grey-500 text-sm px-2 py-2 border-0'),
  ({ isSelected }) => ({
    cursor: 'pointer',
    color: isSelected && s('text-green-600').color,
  })
)
const TabBottomLine = styled.div(
  s('absolute bg-green-600 pin-bl-0', {
    width: 100,
    height: 2,
    bottom: -5,
    transition: 'left 200ms ease-in-out, width 200ms ease-in-out',
  })
)
const ChartContainer = styled.div(s('w-full flex items-start justify-between'))

const lineColors = [s('text-green-600').color, '#EB8019', '#E7E481']

/**
 * Distribute line chart input data (without mapping) into obj with warehouse id as key
 * @returns {object}
 * {
 *  [warehouseId]: object[]
 * }
 */
export const distributeDataByWarehouseId = (data = [], availableWarehouseIds = []) => {
  const dataByWarehouseId = {}

  const dataWithValidWarehouseId = data.filter((d) =>
    availableWarehouseIds.includes(d?.warehouseId)
  )
  const dataWithInvalidWarehouseId = data.filter(
    (d) => !availableWarehouseIds.includes(d?.warehouseId)
  )

  // Generate default properties for map base on available warehouse ids
  availableWarehouseIds.forEach((id) => {
    dataByWarehouseId[id] = []
  })

  dataWithValidWarehouseId.forEach((d) => {
    const dataNotYetExistInMap = isNilOrEmpty(dataByWarehouseId[d?.warehouseId])
    if (dataNotYetExistInMap) dataByWarehouseId[d?.warehouseId] = []

    dataByWarehouseId[d?.warehouseId].push(d)
  })

  // Add data with invalid warehouse Id to map
  if (isNotNilOrEmpty(dataWithInvalidWarehouseId))
    dataByWarehouseId.invalidWarehouseId = dataWithInvalidWarehouseId

  return dataByWarehouseId
}

/**
 * Base on selected warehouse id, select correct warehouse data and map into easy to consume format
 * for line chart and color legend etc.
 * @returns {object[]}
 * [
 *    {
 *      data: [],
 *      style: {},
        warehouseId,
        warehouseName
 *    }
 * ]
 */
export const prepareChartDataForSelectedWarehouse = (
  distributedData = {},
  selectedWarehouseId,
  warehouses = [],
  lineColors = []
) => {
  const formattedData = []
  const displayAllWarehousesData = isNilOrEmpty(selectedWarehouseId)

  if (displayAllWarehousesData) {
    let lineColorIdx = 0

    // Push all data by warehouseId and format it
    mapObjIndexed((dataPoints, warehouseId) => {
      if (warehouseId === 'invalidWarehouseId') {
        formattedData.push({
          data: dataPoints,
          style: {
            color: lineColors[lineColorIdx],
          },
          warehouseName: 'Others',
          warehouseId: 'invalidWarehouseId',
        })
      } else {
        formattedData.push({
          data: dataPoints,
          style: {
            color: lineColors[lineColorIdx],
          },
          warehouseName: warehouses.find(propEq('id', warehouseId))?.name,
          warehouseId,
        })
      }

      lineColorIdx += 1
    }, distributedData)
  } else {
    // Push only the data base on the selected warehouse id
    const selectedWarehouseDataPoints = distributedData[selectedWarehouseId]

    formattedData.push({
      data: selectedWarehouseDataPoints,
      style: {
        color: lineColors[0],
      },
      warehouseName: warehouses.find(propEq('id', selectedWarehouseId))?.name,
      warehouseId: selectedWarehouseId,
    })
  }

  return formattedData
}

/**
 * @returns {object[]}
 * [
 *    {
 *      data: [],
 *      lineStyle: {},
        key: ''
 *    }
 * ]
 */
export const mapToLineChartData = (formattedData = []) => {
  const mapToLineSeriesData = (data) =>
    map(
      (d) => ({
        x: d?.date,
        y: d?.value,
      }),
      data
    )

  return map(
    (d) => ({
      data: mapToLineSeriesData(d?.data),
      lineStyle: d?.style,
      key: d?.warehouseId,
    }),
    formattedData
  )
}

/**
 * @returns {object[]}
 * [
 *    {
 *      label: '',
 *      dotColor: {},
        key: ''
 *    }
 * ]
 */
export const mapToColorLegendData = (formattedData = []) =>
  formattedData.map((d) => ({
    label: d?.warehouseName,
    dotColor: d?.style?.color,
    key: d?.warehouseId,
  }))

const chartPropsBySelectedTab = (selectedTab) => {
  switch (selectedTab) {
    case TABS.LOAN_GRANTED:
      return {
        tooltipValueFormatter: (d) => `$${shortenLargeNumbers(d)}`,
      }
    case TABS.WALVR:
      return {
        tooltipValueFormatter: (d) => `${d}%`,
      }
    case TABS.AVERAGE_LOAN_SIZE:
      return {
        tooltipValueFormatter: (d) => `$${shortenLargeNumbers(d)}`,
      }
    default:
      return {
        tooltipValueFormatter: (d) => `$${shortenLargeNumbers(d)}`,
      }
  }
}

const chooseDataSourceBySelectedTab = (
  loanGrantedDataPoints,
  weightedAverageLvrDataPoints,
  averageLoanSizeDataPoints,
  selectedTab
) => {
  switch (selectedTab) {
    case TABS.LOAN_GRANTED:
      return loanGrantedDataPoints
    case TABS.WALVR:
      return weightedAverageLvrDataPoints
    case TABS.AVERAGE_LOAN_SIZE:
      return averageLoanSizeDataPoints
    default:
      return loanGrantedDataPoints
  }
}

export const PortfolioManagementLineChart = ({
  loanGrantedDataPoints = [],
  weightedAverageLvrDataPoints = [],
  averageLoanSizeDataPoints = [],
  isLoading,
  ...otherProps
}) => {
  const tabBottomLineRef = React.useRef()
  const [selectedTab, setSelectedTab] = React.useState(TABS.LOAN_GRANTED)

  const onTabClicked = (event, tab) => {
    const { offsetLeft, clientWidth } = event.target

    tabBottomLineRef.current.style.left = `${offsetLeft}px`
    tabBottomLineRef.current.style.width = `${clientWidth}px`
    setSelectedTab(tab)
  }

  const { selectedWarehouseId } = useWarehouseDashboard()
  const warehouses = useSelector(selectWarehouses)
  const warehouseIds = pluck('id', warehouses)

  /**
   * Preparing chart data
   */
  const chartProps = chartPropsBySelectedTab(selectedTab)
  const selectedData = chooseDataSourceBySelectedTab(
    loanGrantedDataPoints,
    weightedAverageLvrDataPoints,
    averageLoanSizeDataPoints,
    selectedTab
  )

  const dataByWarehouseId = distributeDataByWarehouseId(selectedData, warehouseIds)
  const formattedData = prepareChartDataForSelectedWarehouse(
    dataByWarehouseId,
    selectedWarehouseId,
    warehouses,
    lineColors
  )

  const lineChartData = mapToLineChartData(formattedData)
  const colorLegendData = mapToColorLegendData(formattedData)

  return (
    <Container {...otherProps}>
      <Header style={s('mb-4')}>
        <Title>Portfolio management</Title>
        <TabsContainer>
          <Tab
            onClick={(e) => onTabClicked(e, TABS.LOAN_GRANTED)}
            isSelected={selectedTab === TABS.LOAN_GRANTED}
            style={s('mr-2')}
          >
            Loan Granted
          </Tab>

          <Tab
            onClick={(e) => onTabClicked(e, TABS.WALVR)}
            isSelected={selectedTab === TABS.WALVR}
          >
            WA LVR
          </Tab>

          <Tab
            onClick={(e) => onTabClicked(e, TABS.AVERAGE_LOAN_SIZE)}
            isSelected={selectedTab === TABS.AVERAGE_LOAN_SIZE}
          >
            Average Loan Size
          </Tab>
          <TabBottomLine ref={tabBottomLineRef} />
        </TabsContainer>
      </Header>

      <ChartContainer>
        <LineChart
          data={lineChartData}
          containerStyle={{
            width: '88%',
            height: 380,
          }}
          chartPadding={{ left: 70, top: 40, bottom: 68, right: 25 }}
          approxHighestValueForEmptyChart={1000000000}
          isLoading={isLoading}
          {...chartProps}
        />

        <ColorLegend
          data={colorLegendData}
          style={{ width: '12%' }}
          isLoading={isLoading}
        />
      </ChartContainer>
    </Container>
  )
}
