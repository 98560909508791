import { defaultTo } from '@soltalabs/ramda-extra'
import ky from 'ky'

// FIXME: refactor this to reflect new API error contract

/**
 * @type {typeof import('index').normalizeError}
 */
export const normalizeError = async (err, logger = console) => {
  // @ts-expect-error this is basically a type-guard for CustomError, but ts doesn't know
  if (err?.isCustomError) return err

  const isDev = process.env.NODE_ENV === 'development'
  const isHttpError = err instanceof ky.HTTPError

  if (isHttpError) {
    const errPayload = await err.response.json()

    if (isDev) logger.error(errPayload)

    return defaultTo(
      { name: 'UnknownError', message: 'Something wrong happens' },
      errPayload
    )
  }

  if (isDev) logger.error(err)
  return { name: 'RuntimeError', message: 'Something wrong happens' }
}
