import { USER_ROLES } from '@vega/constants'
import { values, keys, forEach, uniq } from '@soltalabs/ramda-extra'

const allRoles = values(USER_ROLES)

const removeDuplicates = uniq

const versatileRoleToOptionalRoles = {
  [USER_ROLES.SUPER_ADMIN]: allRoles,
}

const versatileRoles = keys(versatileRoleToOptionalRoles)

export const getUserOptionalRoles = (roles) => {
  const userAssumableRoles = []

  forEach((role) => {
    const isRoleCanAssumeOtherRoles = versatileRoles.includes(role)
    if (isRoleCanAssumeOtherRoles) {
      const assumableRoles = versatileRoleToOptionalRoles[role]
      return userAssumableRoles.push(...assumableRoles)
    }

    return userAssumableRoles.push(role)
  }, roles)

  return removeDuplicates(userAssumableRoles)
}
