import BaseService from '../baseService'
import { isNil } from '@soltalabs/ramda-extra'

export class CreditReportService extends BaseService {
  startCreditCheck(entityId, loanApplicationId) {
    const twoMins = 60 * 2 * 1000
    const config = { json: { entityId, loanApplicationId }, timeout: twoMins }

    return this.client.post('credit-report', config).json()
  }

  getCreditReport(entityId) {
    if (isNil(entityId)) throw new Error('Invalid Entity Id')

    return this.client.get(`credit-report?entityId=${entityId}`).json()
  }
}
