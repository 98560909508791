import React from 'react'
import { defaultTo } from '@soltalabs/ramda-extra'
import { styled, s } from '@vega/styled'
import { PieChart } from './PieChart'

const legendColors = ['rgba(60,175,165,1)', 'rgba(135,207,145,1)', 'rgba(235,149,76,1)']

const Container = styled.div(s('flex justify-between items-center', { width: 1300 }))

/**
 * @param {Object} customerType - Include different customer types' data
 * @returns {Object[]} - [{label, frequency, color}]
 */
const mapToCustomerTypePieChartData = (customerType) => [
  {
    label: 'Single',
    frequency: defaultTo(0, customerType?.single?.total),
    color: legendColors[0],
  },
  {
    label: 'Joint',
    frequency: defaultTo(0, customerType?.joint?.total),
    color: legendColors[1],
  },
  {
    label: 'Others',
    frequency: defaultTo(0, customerType?.others?.total),
    color: legendColors[2],
  },
]

const mapToPropertyCategoryChartData = (propertyCategory) => [
  {
    label: 'Apartments',
    frequency: defaultTo(0, propertyCategory?.apartment?.total),
    color: legendColors[0],
  },
  {
    label: 'Houses',
    frequency: defaultTo(0, propertyCategory?.house?.total),
    color: legendColors[1],
  },
  {
    label: 'Others',
    frequency: defaultTo(0, propertyCategory?.others?.total),
    color: legendColors[2],
  },
]

const mapToPostcodeProfileChartData = (postCodeProfile) => [
  {
    label: 'Single',
    frequency: defaultTo(0, postCodeProfile?.apartment?.total),
    color: legendColors[0],
  },
  {
    label: 'Multiple',
    frequency: defaultTo(0, postCodeProfile?.house?.total),
    color: legendColors[1],
  },
]

const Breakdowns = ({ customerType = {}, propertyCategory = {}, isLoading }) => {
  return (
    <Container>
      <PieChart
        title="Applicant Breakdown"
        chartData={mapToCustomerTypePieChartData(customerType)}
        isLoading={isLoading}
      />

      <PieChart
        title="Property Breakdown"
        chartData={mapToPropertyCategoryChartData(propertyCategory)}
        isLoading={isLoading}
      />

      <PieChart
        title="Postcode Profile"
        chartData={mapToPostcodeProfileChartData({})}
        isLoading={isLoading}
      />
    </Container>
  )
}

export { Breakdowns }
