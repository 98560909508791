import React from 'react'
import { prop } from '@soltalabs/ramda-extra'
import { useListBox } from '@react-aria/listbox'
import { mergeProps } from '@react-aria/utils'
import { FocusScope } from '@react-aria/focus'
import { useOverlay, DismissButton } from '@react-aria/overlays'
import { styled, s } from '@vega/styled'

import { Option } from './Option'

const Menu = styled.ul(
  s(
    'absolute w-full mt-2 border-solid border-1 border-grey-300 bg-white p-0 py-2 z-1',
    {
      boxSizing: 'border-box',
      listStyle: 'none',
      borderRadius: '6px 0px 6px 6px',
      '&:focus': { outline: '0' },
      overflowY: 'scroll',
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
      '&::-webkit-scrollbar': {
        width: 0,
        height: 0,
      },
    }
  ),
  prop('selectMenuStyles')
)

function ListBoxPopup({ selectMenuStyles, state, menuId, ...otherProps }) {
  const ref = React.useRef()

  const { listBoxProps } = useListBox(
    {
      autoFocus: true,
      disallowEmptySelection: true,
      ...otherProps,
    },
    state,
    ref
  )

  const overlayRef = React.useRef()
  const { overlayProps } = useOverlay(
    {
      onClose: () => state.close(),
      shouldCloseOnBlur: true,
      isOpen: state.isOpen,
      isDismissable: true,
    },
    overlayRef
  )

  return (
    <FocusScope restoreFocus>
      <div {...overlayProps} ref={overlayRef}>
        <DismissButton onDismiss={() => state.close()} />
        <Menu
          {...mergeProps(listBoxProps, otherProps)}
          selectMenuStyles={selectMenuStyles}
          ref={ref}
          data-test-id={menuId}
        >
          {[...state.collection].map((item) => (
            <Option key={item.key} item={item} state={state} />
          ))}
        </Menu>
        <DismissButton onDismiss={() => state.close()} />
      </div>
    </FocusScope>
  )
}

export { ListBoxPopup }
