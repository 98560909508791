import React, { useRef } from 'react'
import { useComboBox } from '@react-aria/combobox'
import { useComboBoxState } from '@react-stately/combobox'
import { useFilter } from '@react-aria/i18n'
import { useButton } from '@react-aria/button'
import { isNilOrEmpty, and, isNotNilOrEmpty } from '@soltalabs/ramda-extra'

import { styled, s } from '@vega/styled'
import { ListBoxPopup } from './ListBoxPopup'

import { ReactComponent as ArrowIcon } from '@vega/components/src/assets/images/dropdownArrow.svg'
import { ReactComponent as XCircleIcon } from '@vega/components/src/assets/images/x-circle.svg'
import { FormMessage } from '@vega/components'

const Root = styled.div(
  s('inline-flex flex-column w-full', { boxSizing: 'border-box' })
)
const Label = styled.label(s('text-grey-600 font-normal text-base mb-2'))

const InputContainer = styled.div(
  s('flex items-center relative border-1 border-solid border-grey-200 bg-transparent'),
  s('p-4 w-full h-full'),
  {
    boxSizing: 'border-box',
    borderRadius: 6,
    borderTopRightRadius: 0,
    ':focus-within': s('border-green-400', { boxShadow: '0 0 0 1px #58C6BD' }),
    ':hover': s('border-green-400'),
  },
  ({ isTouched }) => isTouched && s('border-green-400'),
  ({ hasError, isTouched }) =>
    hasError &&
    isTouched &&
    s('border-error-400', {
      ':hover': s('border-error-400'),
      ':focus-within': s('border-green-400', { boxShadow: '0 0 0 1px #58C6BD' }),
    }),
  ({ isFilled }) => isFilled && s('border-grey-300'),
  ({ isReadOnly }) =>
    isReadOnly &&
    s('bg-grey-100 border-1 border-solid border-transparent', {
      pointerEvents: 'none',
    }),
  ({ isDisabled }) => isDisabled && s('bg-grey-200', { pointerEvents: 'none' })
)

const Input = styled.input(
  s('h-full w-full border-0 bg-transparent'),
  s('text-grey-800 text-base font-medium'),
  {
    outline: 'none',
    '&::placeholder': s('text-grey-500'),
    '&::-webkit-search-decoration, ::-webkit-search-cancel-button, ::-webkit-search-results-button, ::-webkit-search-results-decoration': {
      WebkitAppearance: 'none',
    },
  },
  ({ isReadOnly }) => isReadOnly && s('text-grey-800'),
  ({ isDisabled }) => isDisabled && s('text-grey-400')
)

const ActionButton = styled.button(
  s('bg-transparent border-0 p-0 flex items-center justify-center', {
    height: 22,
    width: 22,
    outline: 'none',
    cursor: 'pointer',
  })
)

function ComboBox({
  label,
  placeholder,
  options,
  onSelectionChange,
  onInputChange,
  onOpenChange,
  readOnly,
  disabled,
  touched,
  error,
  listBoxStyles,
  optionStyles,
  labelStyles,
  testId,
  menuId,
  name,
  ...props
}) {
  const { startsWith } = useFilter({ sensitivity: 'base' })

  const state = useComboBoxState({
    ...props,
    onSelectionChange,
    onInputChange,
    onOpenChange,
    defaultFilter: startsWith,
  })

  const triggerRef = useRef()
  const inputRef = useRef()
  const listBoxRef = useRef()
  const popoverRef = useRef()

  const {
    buttonProps: triggerProps,
    inputProps,
    listBoxProps,
    labelProps,
  } = useComboBox(
    {
      placeholder,
      label,
      inputRef,
      buttonRef: triggerRef,
      listBoxRef,
      popoverRef,
      ...props,
    },
    state
  )

  const { buttonProps } = useButton(triggerProps, triggerRef)

  return (
    <Root {...props}>
      {label && (
        <Label {...labelProps} {...labelStyles}>
          {label}
        </Label>
      )}
      <InputContainer
        isFilled={isNotNilOrEmpty(state.selectedKey)}
        isDisabled={disabled}
        isReadOnly={readOnly}
        isTouched={touched}
        hasError={Boolean(error)}
      >
        <Input
          {...inputProps}
          type="search"
          ref={inputRef}
          isDisabled={disabled}
          isReadOnly={readOnly}
          onBlur={() => state.setFocused(false)}
          data-test-id={testId}
        />

        {and(isNilOrEmpty(state.selectedKey), state.isFocused) ? (
          <ActionButton type="button">
            <XCircleIcon aria-hidden="true" width={20} height={20} />
          </ActionButton>
        ) : (
          <ActionButton {...buttonProps} ref={triggerRef}>
            <ArrowIcon aria-hidden="true" width={8} />
          </ActionButton>
        )}
      </InputContainer>
      {state.isOpen && (
        <ListBoxPopup
          {...listBoxProps}
          shouldUseVirtualFocus
          listBoxRef={listBoxRef}
          popoverRef={popoverRef}
          state={state}
          listBoxStyles={listBoxStyles}
          optionStyles={optionStyles}
          menuId={menuId}
        />
      )}
      <FormMessage message={error} visible={Boolean(error && touched)} id={name} />
    </Root>
  )
}

export { ComboBox }
